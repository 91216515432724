<div>
	<div class="background" [ngClass]="data.type"></div>
	<div class="icon" [ngStyle]="{ height: snackbarHeight + 'px' }">
		<i class="fi" [ngClass]="icons[data.type] + ' ' + data.type"></i>
	</div>
</div>

<div class="info">
    <div class="title">
        {{ data.title | translate }}
    </div>
    <div class="description">
        {{ data.message | translate }}
    </div>
</div>
