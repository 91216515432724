<ng-template #drawerTemplate>
	<ng-scrollbar #ngScrollbar visibility="hover" style="height: 100vh;">
		<div class="modules responsive-navigation-modules">

			<a [routerLink]="['/home']" class="title">
				<img src="/assets/images/logo-full.svg" alt="Logo">
			</a>

			<mat-accordion>
				@for (moduleKey of moduleKeys; track moduleKey) {

					@if (moduleKey === 'core') {
						<div class="main-items">
							@for (item of menusByModule[moduleKey]; track item.label) {
								<app-menu-item
										[menu]="item"
										[initWithMenusExpanded]="true"
								></app-menu-item>
							}
						</div>
					} @else {
						@if (menusByModule[moduleKey].length > 0) {
							<mat-expansion-panel [(expanded)]="expandedModule[moduleKey]">
								<mat-expansion-panel-header>
									<mat-panel-title>
										<div class="panel-title">
											<div class="first">
												@if (modulesByModuleKey[moduleKey]) {
													<i class="fi" [ngClass]="modulesByModuleKey[moduleKey].icon"></i>
												}
												{{ 'module.' + moduleKey | translate }}
											</div>
											@if (notificationsByModule[moduleKey] && notificationsByModule[moduleKey] > 0) {
												<span class="badge three"> {{ notificationsByModule[moduleKey] }} </span>
											}
										</div>
									</mat-panel-title>
								</mat-expansion-panel-header>

								@for (item of menusByModule[moduleKey]; track item.label) {
									<app-menu-item
											[menu]="item"
											[initWithMenusExpanded]="true"
									></app-menu-item>
								}
							</mat-expansion-panel>
						}
					}
				}
			</mat-accordion>
		</div>
	</ng-scrollbar>
</ng-template>
